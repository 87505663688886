/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Button, Layout, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { LockOutlined } from "@ant-design/icons";

import { resetPassword } from "../../redux/actions/resetActions";
import { dataBaseLogo } from "../../assets/images";

import "./styles.scss";

const passwordRegEx = new RegExp("(?=.*[a-zA-Z])");

const validatePassword = (rule, value, callback) => {
  if (!value) {
    callback("Please input your password!");
  } else if (value && value.length < 8 && value.length > 0) {
    callback("Password must be at least 8 characters");
  } else if (!passwordRegEx.test(value)) {
    callback("Password must be contain letters");
  } else callback();
};

const validation_schema = {
  password: [{ validator: validatePassword }],
};

const NewPasswordPage = () => {
  const dispatch = useDispatch();
  const reset = useSelector((state) => state.reset);
  const messageState = reset.message;
  const location = useLocation();

  const [passwordForm] = Form.useForm();

  const onFinishPassword = (values) => {
    const token = location.search.slice(7);
    values.token = token;
    Promise.resolve(dispatch(resetPassword(values))).then(() => {});
  };

  useEffect(() => {
    if (messageState)
      if (messageState?.status === 500) {
        message.error(messageState?.message);
      } else {
        if (messageState === "reset token not exist or expired") {
          message.error(messageState);
        } else {
          message.success("Success");
          window.location = "/";
        }
      }
  }, [messageState]);

  return (
    <Layout
      className="reset-page"
      style={{ minHeight: "100vh", background: "#92C83E" }}
    >
      <div className="logo-group">
        <div className="data-base-logo">{dataBaseLogo()}</div>
      </div>

      <Form
        className="reset-form"
        name="reset-password-form"
        form={passwordForm}
        onFinish={onFinishPassword}
      >
        <Form.Item
          className="password"
          label="Password"
          name="password"
          rules={validation_schema.password}
        >
          <Input.Password
            placeholder="Please enter new password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            style={{
              borderRadius: "8px",
            }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          className="password"
          name="confirm-password"
          rules={validation_schema.password}
        >
          <Input.Password
            placeholder="Please confirm new password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="confirm-password"
            style={{
              borderRadius: "8px",
            }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <div className="flex-buttons">
          <Form.Item className="submit-button">
            <Button htmlType="submit">Save</Button>
          </Form.Item>
        </div>
      </Form>
    </Layout>
  );
};

export default NewPasswordPage;
