import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  CLEAR_LOGIN_INFO,
} from "../constants/login";
import { LOGIN } from "../../helpers/apiConstants";
import _ from "lodash";

// const URL = process.env.REACT_APP_API_URL;
const URL = "https://tradin-dsa.com/";
// const URL = "https://tradin-dsa.com/";

export const getData = (userData) => {
  return async (dispatch) => {
    dispatch({ type: USER_LOGIN_REQUEST });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    };

    let payload = null;

    try {
      const response = await fetch(`${URL}${LOGIN}`, requestOptions);
      const result = await response.json();
      payload = result;
    } catch (error) {
      console.log("Error fetching user data from the server", error);
    }

    if (payload && payload.error) {
      dispatch({ type: USER_LOGIN_ERROR });
    }

    if (!_.isEmpty(payload)) {
      if (!payload.error) {
        localStorage.setItem("token", payload.token);
      }
    }

    return dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: payload,
    });
  };
};

export const clearLoginInfo = () => ({
  type: CLEAR_LOGIN_INFO,
});
