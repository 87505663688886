import {
  GET_SEARCH_RESULTS_REQUEST,
  GET_SEARCH_RESULTS_ERROR,
  GET_SEARCH_RESULTS_SUCCESS,
  EXPORT_TO_XLS_REQUEST,
  EXPORT_TO_XLS_ERROR,
  EXPORT_TO_XLS_SUCCESS,
  EXPORT_TO_PDF_REQUEST,
  EXPORT_TO_PDF_ERROR,
  EXPORT_TO_PDF_SUCCESS,
  GET_SEARCH_FIELDS,
  GET_TABLE_PARAMS,
  CLEAR_ALL,
  SEARCH_BY_COUNTRIES_REQUEST,
  SEARCH_BY_COUNTRIES_SUCCESS,
  SEARCH_BY_COUNTRIES_ERROR,
  SEARCH_BY_APPLICANTS_REQUEST,
  SEARCH_BY_APPLICANTS_ERROR,
  SEARCH_BY_APPLICANTS_SUCCESS,
  SEARCH_BY_ACTIVITIES_REQUEST,
  SEARCH_BY_ACTIVITIES_ERROR,
  SEARCH_BY_ACTIVITIES_SUCCESS,
  SEARCH_BY_PRODUCTS_REQUEST,
  SEARCH_BY_PRODUCTS_ERROR,
  SEARCH_BY_PRODUCTS_SUCCESS,
  SEARCH_BY_SOURCES_REQUEST,
  SEARCH_BY_SOURCES_ERROR,
  SEARCH_BY_SOURCES_SUCCESS,
  SEARCH_BY_STANDARTS_REQUEST,
  SEARCH_BY_STANDARTS_ERROR,
  SEARCH_BY_STANDARTS_SUCCESS
} from "../constants/searchPage";

const initialState = {
  isLoading: false,
  hasError: false,
  success: false,
  exportToXlsError: false,
  exportToXlsSuccess: false,
  exportToPdfError: false,
  exportToPdfSuccess: false,
  results: null,
  searchFieldsData: null,
  tableParams: null,
  countriesList: null,
  applicantsList: null,
  activitiesList: null,
  productsList: null,
  sourcesList: null,
  standartsList: null
};

export const search = (state = initialState, action) => {
  const { type, searchResults, searchFieldsValues, tableParams, payload } = action;

  switch (type) {
    case GET_SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case GET_SEARCH_RESULTS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        success: false,
      };

    case GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
        results: searchResults,
      };

    case EXPORT_TO_XLS_REQUEST:
      return {
        ...state,
        isLoading: true,
        exportToXlsError: false,
      };

    case EXPORT_TO_XLS_ERROR:
      return {
        ...state,
        isLoading: false,
        exportToXlsError: true,
        exportToXlsSuccess: false,
      };

    case EXPORT_TO_XLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        exportToXlsError: false,
        exportToXlsSuccess: true,
      };

    case EXPORT_TO_PDF_REQUEST:
      return {
        ...state,
        isLoading: true,
        exportToPdfError: false,
      };

    case EXPORT_TO_PDF_ERROR:
      return {
        ...state,
        isLoading: false,
        exportToPdfError: true,
        exportToPdfSuccess: false,
      };

    case EXPORT_TO_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        exportToPdfError: false,
        exportToPdfSuccess: true,
      };

    case GET_SEARCH_FIELDS:
      return {
        ...state,
        searchFieldsData: searchFieldsValues
      }

    case GET_TABLE_PARAMS:
      return {
        ...state,
        tableParams: tableParams
      }

    case CLEAR_ALL:
      return {
        ...initialState
      };

    case SEARCH_BY_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case SEARCH_BY_COUNTRIES_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        success: false,
      };

    case SEARCH_BY_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
        countriesList: payload,
      };

    case SEARCH_BY_APPLICANTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case SEARCH_BY_APPLICANTS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        success: false,
      };

    case SEARCH_BY_APPLICANTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
        applicantsList: payload,
      };

    case SEARCH_BY_ACTIVITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case SEARCH_BY_ACTIVITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        success: false,
      };

    case SEARCH_BY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
        activitiesList: payload,
      };
    
    case SEARCH_BY_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case SEARCH_BY_PRODUCTS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        success: false,
      };

    case SEARCH_BY_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
        productsList: payload,
      };

  case SEARCH_BY_SOURCES_REQUEST:
    return {
      ...state,
      isLoading: true,
      hasError: false,
    };

  case SEARCH_BY_SOURCES_ERROR:
    return {
      ...state,
      isLoading: false,
      hasError: true,
      success: false,
    };

  case SEARCH_BY_SOURCES_SUCCESS:
    return {
      ...state,
      isLoading: false,
      hasError: false,
      success: true,
      sourcesList: payload,
    };

  case SEARCH_BY_STANDARTS_REQUEST:
    return {
      ...state,
      isLoading: true,
      hasError: false,
    };

  case SEARCH_BY_STANDARTS_ERROR:
    return {
      ...state,
      isLoading: false,
      hasError: true,
      success: false,
    };

  case SEARCH_BY_STANDARTS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      hasError: false,
      success: true,
      standartsList: payload,
    };

    default:
      return state;
  }
};
