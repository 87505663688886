import {
  OPEN_ADD_USER_MODAL,
  CLOSE_ADD_USER_MODAL
} from "../constants/adminPage";

export const addNewUserModalOpen = () => ({
  type: OPEN_ADD_USER_MODAL
})

export const addNewUserModalClose = () => ({
  type: CLOSE_ADD_USER_MODAL
})