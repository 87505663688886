import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../redux/actions/usersActions";
import {
  deleteUser,
  setEnableDisableUser,
  updateUser,
  changePassword,
} from "../../redux/actions/userActions";

const User = ({ user, page, pageSize }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [editUser, setEditUser] = useState(false);
  const id = user && user.id;
  const admin = user && user.roles[0] === "ROLE_ADMIN";
  const useriSBlocked = user && !user.enabled;

  const handleEditActive = () => {
    setEditUser(true);
  };

  const handleEditDisactive = (person, id) => {
    Promise.resolve(handleUserEdit(person))
      .then(() => {
        const password = form.getFieldValue("password");
        if (password && password !== undefined) {
          const passwordForSend = {
            password: password,
          };
          dispatch(changePassword(id, passwordForSend));
        }
      })
      .then(() => {
        dispatch(getAllUsers(page, pageSize));
      })
      .then(() => {
        setEditUser(false);
      });
  };

  const handleDeleteUser = (id) => {
    Promise.resolve(dispatch(deleteUser(id))).then(() => {
      dispatch(getAllUsers(page, pageSize));
    });
  };

  const handleEnableDisableUser = (id, option) => {
    Promise.resolve(dispatch(setEnableDisableUser(id, option))).then(() => {
      dispatch(getAllUsers(page, pageSize));
    });
  };

  const setUserToAdmin = (person, type) => {
    const { email, id, name } = person;

    const changePermissions = {
      email: email,
      id: id,
      name: name,
      roles: [type],
    };

    Promise.resolve(dispatch(updateUser(changePermissions))).then(() => {
      dispatch(getAllUsers(page, pageSize));
    });
  };

  const handleUserEdit = (person) => {
    const fields = form.getFieldsValue();
    const { id, roles } = person;
    const { email, username } = fields;

    const editUser = {
      email: email,
      id: id,
      name: username,
      roles: roles,
    };

    dispatch(updateUser(editUser));
  };

  return (
    <Form
      className="edit-user-form"
      name="edit-form"
      layout="vertical"
      form={form}
      initialValues={{
        username: user.name,
        email: user.email,
      }}
    >
      <div className="edit-inputs-group">
        <Form.Item
          className="user-email"
          label="email"
          name="email"
          rules={[
            {
              whitespace: true,
            },
          ]}
        >
          {editUser ? (
            <Input
              style={{
                borderRadius: "8px",
              }}
            />
          ) : (
            <>
              <span className="email">{user.email}</span>
              <div className="user-info-wrapper">
                {admin && (
                  <span className="admin-title">
                    This user has administrator rights
                  </span>
                )}
                {useriSBlocked && (
                  <span className="block-title">This user was blocked</span>
                )}
              </div>
            </>
          )}
        </Form.Item>
        <Form.Item
          className="user-name"
          label="username"
          name="username"
          rules={[
            {
              whitespace: true,
            },
          ]}
        >
          {editUser ? (
            <Input
              style={{
                borderRadius: "8px",
              }}
            />
          ) : (
            <span className="name">{user.name}</span>
          )}
        </Form.Item>
        <Form.Item
          className="password"
          label="Password"
          name="password"
          rules={[
            {
              whitespace: true,
            },
          ]}
        >
          {editUser ? (
            <Input
              type="password"
              style={{
                borderRadius: "8px",
              }}
            />
          ) : (
            <span className="user-password">
              Press Edit to change the current password.
            </span>
          )}
        </Form.Item>
      </div>
      <Form.Item className="options-buttons-group">
        <Button className="delete-users" onClick={() => handleDeleteUser(id)}>
          Delete
        </Button>
        <Button
          className="block-users"
          onClick={() => handleEnableDisableUser(id, "disable")}
        >
          Temporarily block
        </Button>
        <Button
          className="remove-block"
          onClick={() => handleEnableDisableUser(id, "enable")}
        >
          Remove block
        </Button>
        <Button
          className="add-admin-permission"
          onClick={() => {
            setUserToAdmin(user, "ROLE_ADMIN");
          }}
        >
          Promote to admin
        </Button>
        <Button
          className="add-admin-permission"
          onClick={() => {
            setUserToAdmin(user, "ROLE_USER");
          }}
        >
          Remove admin role
        </Button>
        {!editUser ? (
          <Button className="edit-user" onClick={handleEditActive}>
            Edit
          </Button>
        ) : (
          <Button
            className="edit-user"
            onClick={() => handleEditDisactive(user, id)}
          >
            Save
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default User;
