export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const CREATE_NEW_USER_REQUEST = "CREATE_NEW__USER_REQUEST";
export const CREATE_NEW_USER_ERROR = "CREATE_NEW__USER_SUCCESS";
export const CREATE_NEW_USER_GET_USER_SUCCESS = "CREATE_NEW_USER_GET_USER_SUCCESS";


export const DELETE_CURRENT_USER_REQUEST = "DELETE_CURRENT_USER_REQUEST";
export const DELETE_CURRENT_USER_ERROR = "DELETE_CURRENT_USER_ERROR";
export const DELETE_CURRENT_USER_SUCCESS = "DELETE_CURRENT_USER_SUCCESS";

export const ENABLE_DISABLE_USER_REQUEST = "ENABLE_DISABLE_USER_REQUEST";
export const ENABLE_DISABLE_USER_ERROR = "ENABLE_DISABLE_USER_ERROR";
export const ENABLE_DISABLE_USER_SUCCESS = "ENABLE_DISABLE_USER_SUCCESS";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const CHANGE_USER_PASSWORD_REQUEST = "CHANGE_USER_PASSWORD_REQUEST";
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";

