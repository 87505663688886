export const LOGIN = "api/auth/login";
export const USERS = "api/user";
export const USER = "api/user/info";
export const USER_FORGOT_PASSWORD = "api/user/forgot_password";
export const USER_RESET_PASSWORD = "api/user/reset_password";
export const SEARCH = "api/certification/search";
export const EXPORT_XLS = "api/certification/search/export_excel";
export const EXPORT_PDF = "api/certification/search/export_pdf";
export const SEARCH_BY_APPLICANTS = "api/autocomplete/applicants";
export const SEARCH_BY_COUNTRIES = "api/autocomplete/countries";
export const SEARCH_BY_ACTIVITIES = "api/autocomplete/certified_activities";
export const SEARCH_BY_PRODUCTS = "api/autocomplete/certified_products";
export const SEARCH_BY_SOURCES = "api/autocomplete/sources";
export const SEARCH_BY_STANDARTS = "api/autocomplete/standards";
