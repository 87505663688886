import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Input } from "antd";
import { addNewUserModalClose } from "../../redux/actions/adminPageActions";
import { createNewUser } from "../../redux/actions/userActions";
import { getAllUsers } from "../../redux/actions/usersActions";
import _ from "lodash";

const AddNewUserModal = ({ page, pageSize }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isModalVisible = useSelector(
    (state) => state.adminPage.addUserModlaOpen
  );

  const userBasicStructure = {
    email: "string",
    enabled: true,
    name: "string",
    password: "string",
    roles: ["ROLE_USER"],
  };

  const handleOk = () => {
    const userFields = form.getFieldsValue();
    const formErrors = form.getFieldsError();
    const checkEmptyFields = Object.values(userFields).includes(undefined);
    let errorsArray = [];
    _.map(formErrors, (item) =>
      _.map(item.errors, (error) => errorsArray.push(error))
    );
    const { email, password, username } = userFields;
    const userDataForSend = {
      ...userBasicStructure,
      email: email,
      password: password,
      name: username,
    };

    if (!checkEmptyFields && _.isEmpty(errorsArray)) {
      dispatch(createNewUser(userDataForSend));
    }
  };

  const handleCreateUser = () => {
    Promise.resolve(handleOk())
      .then(() => {
        dispatch(getAllUsers(page, pageSize));
      })
      .then(() => {
        handleCancel();
      });
  };

  const handleCancel = () => {
    dispatch(addNewUserModalClose());
    form.resetFields();
  };

  return (
    <Modal
      visible={isModalVisible}
      okText="Create"
      okButtonProps={{ form: "create-form", key: "submit", htmlType: "submit" }}
      onOk={handleCreateUser}
      onCancel={handleCancel}
      width="300px"
    >
      <Form
        className="create-user-form"
        name="create-form"
        form={form}
        layout="vertical"
      >
        <Form.Item
          shouldUpdate={true}
          className="user-email"
          label="email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
              whitespace: true,
              type: "email",
            },
          ]}
        >
          <Input
            style={{
              borderRadius: "8px",
            }}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={true}
          className="user-name"
          label="username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
              whitespace: true,
            },
          ]}
        >
          <Input
            style={{
              borderRadius: "8px",
            }}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={true}
          className="password"
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
              whitespace: true,
            },
          ]}
        >
          <Input
            type="password"
            style={{
              borderRadius: "8px",
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewUserModal;
