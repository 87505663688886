import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const PrivateRoute = ({
  component: Component,
  token,
  ...rest }) => (
  <Route
    {...rest}
    component={props => (
      token ? (
        <div>
          <Header />
          <Component {...props}/>
          <Footer />
        </div>
      ) : (
        <Redirect to="/" />
      )
    )}
  />
)

const mapDispatchToProps = () => {
  return {
    token: localStorage.getItem('token')
  }
}


export default connect(mapDispatchToProps)(PrivateRoute);
