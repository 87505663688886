import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  CLEAR_LOGIN_INFO,
} from "../constants/login";

const initialState = {
  success: false,
  isLoading: false,
  hasError: false,
  token: localStorage.getItem("token") || null,
};

export const login = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        success: true,
        token: payload,
      };
    case USER_LOGIN_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        success: false,
      };
    case CLEAR_LOGIN_INFO:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
