/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Tooltip, AutoComplete } from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  getSeachDataFromFields,
  getSearchResults,
  clearAllSearchData,
  getCountriesSearchResults,
  getApplicantsSearchResults,
  getActivitiesSearchResults,
  getProductsSearchResults,
  getSourcesSearchResults,
  getStandardSearchResults,
} from "../../redux/actions/searchActions";
import _ from "lodash";

const defaultFieldsArray = {
  sources: [{ id: uuidv4() }],
  countries: [{ id: uuidv4() }],
  applicants: [{ id: uuidv4() }],
  certifiedActivities: [{ id: uuidv4() }],
  certifiedProducts: [{ id: uuidv4() }],
  standards: [{ id: uuidv4() }],
};

const SearchForm = ({ setLoader }) => {
  const dispatch = useDispatch();
  const tableParams = useSelector((state) => state.search.tableParams);
  const searchData = useSelector((state) => state.search);
  const {
    countriesList,
    applicantsList,
    activitiesList,
    productsList,
    sourcesList,
    standartsList,
  } = !_.isEmpty(searchData) && searchData;
  const pageSize =
    tableParams && tableParams.pageSize ? tableParams.pageSize : 25;
  const sortType = tableParams && tableParams.sortType;
  const [form] = Form.useForm();
  const [companyNameArray, setCompanyNameArray] = useState(defaultFieldsArray);
  const [countryOptions, setCountryOptions] = useState([]);
  const [applicantsOptions, setApplicantsOptions] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [sourcesOptions, setsourcesOptions] = useState([]);
  const [standardsOptions, setstandardsOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [activities, setActivities] = useState([]);
  const [products, setProducts] = useState([]);
  const [sources, setSources] = useState([]);
  const [standarts, setStandarts] = useState([]);

  const [fields, setFields] = useState(null);

  useEffect(() => {
    dispatch(getCountriesSearchResults());
    dispatch(getApplicantsSearchResults());
    dispatch(getActivitiesSearchResults());
    dispatch(getProductsSearchResults());
    dispatch(getSourcesSearchResults());
    dispatch(getStandardSearchResults());
  }, []);

  useEffect(() => {
    setCountries(countriesList);
    setApplicants(applicantsList);
    setActivities(activitiesList);
    setProducts(productsList);
    setSources(sourcesList);
    setStandarts(standartsList);
  }, [
    activitiesList,
    applicantsList,
    countriesList,
    productsList,
    sourcesList,
    standartsList,
  ]);

  const onCountrySearch = (searchText) => {
    const changeDataFormating =
      countries &&
      _.map(countries, (country) => {
        return {
          label: country,
          value: country,
        };
      });

    setCountryOptions(
      !searchText
        ? []
        : _.filter(changeDataFormating, (item) => {
            return _.includes(
              item.value.toLowerCase(),
              searchText.toLowerCase()
            );
          })
    );
  };

  const onApplicantsSearch = (searchText) => {
    const changeDataFormating =
      applicants &&
      _.map(applicants, (applicant) => {
        return {
          label: applicant,
          value: applicant,
        };
      });

    setApplicantsOptions(
      !searchText
        ? []
        : _.filter(changeDataFormating, (item) => {
            return _.includes(
              item.value.toLowerCase(),
              searchText.toLowerCase()
            );
          })
    );
  };

  const onActivitiesSearch = (searchText) => {
    const changeDataFormating =
      activities &&
      _.map(activities, (activitie) => {
        return {
          label: activitie,
          value: activitie,
        };
      });

    setActivitiesOptions(
      !searchText
        ? []
        : _.filter(changeDataFormating, (item) => {
            return _.includes(
              item.value.toLowerCase(),
              searchText.toLowerCase()
            );
          })
    );
  };

  const onProductsSearch = (searchText) => {
    const changeDataFormating =
      products &&
      _.map(products, (product) => {
        return {
          label: product,
          value: product,
        };
      });

    setProductsOptions(
      !searchText
        ? []
        : _.filter(changeDataFormating, (item) => {
            return _.includes(
              item.value.toLowerCase(),
              searchText.toLowerCase()
            );
          })
    );
  };

  const onSourcesSearch = (searchText) => {
    const changeDataFormating =
      sources &&
      _.map(sources, (source) => {
        return {
          label: source,
          value: source,
        };
      });

    setsourcesOptions(
      !searchText
        ? []
        : _.filter(changeDataFormating, (item) => {
            return _.includes(
              item.value.toLowerCase(),
              searchText.toLowerCase()
            );
          })
    );
  };

  const onStandardsSearch = (searchText) => {
    const changeDataFormating =
      standarts &&
      _.map(standarts, (standart) => {
        return {
          label: standart,
          value: standart,
        };
      });

    setstandardsOptions(
      !searchText
        ? []
        : _.filter(changeDataFormating, (item) => {
            return _.includes(
              item.value.toLowerCase(),
              searchText.toLowerCase()
            );
          })
    );
  };

  const onFinish = (values) => {
    let sources = [];
    let applicants = [];
    let countries = [];
    let certifiedActivities = [];
    let certifiedProducts = [];
    let standards = [];

    setLoader(true);

    const fieldsValues = {
      sources: sources,
      applicants: applicants,
      countries: countries,
      certifiedActivities: certifiedActivities,
      certifiedProducts: certifiedProducts,
      standards: standards,
    };

    _.map(Object.keys(values), (key) => {
      if (key.includes("sources")) {
        values[key] !== undefined && sources.push(values[key]);
      }

      if (key.includes("applicants")) {
        values[key] !== undefined && applicants.push(escape(values[key]));
      }

      if (key.includes("countries")) {
        values[key] !== undefined && countries.push(values[key]);
      }

      if (key.includes("certifiedActivities")) {
        values[key] !== undefined && certifiedActivities.push(values[key]);
      }

      if (key.includes("certifiedProducts")) {
        values[key] !== undefined && certifiedProducts.push(values[key]);
      }

      if (key.includes("standards")) {
        values[key] !== undefined && standards.push(values[key]);
      }
    });

    Promise.resolve(dispatch(getSeachDataFromFields(fieldsValues))).then(() => {
      setFields(fieldsValues);
      setLoader(false);
    });
  };

  useEffect(() => {
    setLoader(true);
    const abortController = new AbortController();
    Promise.resolve(
      dispatch(getSearchResults(pageSize, 0, sortType, fields, abortController))
    ).then(() => {
      setLoader(false);
    });

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const renderSource = () => {
    return _.map(companyNameArray.sources, (source, index) => {
      const showLabel = index === 0;
      const lastItem =
        companyNameArray.sources[companyNameArray.sources.length - 1].id ===
        source.id;
      return (
        <div key={index} className="source-wrapper">
          {index > 0 && (
            <Tooltip title="Delete field">
              <Button
                className="source-button-delete button"
                icon={<MinusOutlined />}
                onClick={() => handleDeleteField("sources", source.id)}
              />
            </Tooltip>
          )}
          {lastItem && (
            <Tooltip title="Add new certification body field">
              <Button
                className="source-button button"
                icon={<PlusOutlined />}
                onClick={() => handleAddNewField("sources")}
              />
            </Tooltip>
          )}
          <Form.Item
            className="source"
            label={`${showLabel ? `Certification body: ` : ""}`}
            name={`sources-${source.id}`}
          >
            <AutoComplete
              style={{
                width: 300,
              }}
              options={sourcesOptions}
              onSearch={onSourcesSearch}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
        </div>
      );
    });
  };

  const renderCompanyName = () => {
    return _.map(companyNameArray.applicants, (applicant, index) => {
      const showLabel = index === 0;
      const lastItem =
        companyNameArray.applicants[companyNameArray.applicants.length - 1]
          .id === applicant.id;
      return (
        <div key={index} className="company-name-wrapper">
          <Form.Item
            className="company-name"
            label={`${showLabel ? "company name: " : ""}`}
            name={`applicants-${applicant.id}`}
          >
            <AutoComplete
              style={{
                width: 300,
              }}
              options={applicantsOptions}
              onSearch={onApplicantsSearch}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
          {lastItem && (
            <Tooltip title="Add new company name field">
              <Button
                className="company-name-button button"
                icon={<PlusOutlined />}
                onClick={() => handleAddNewField("applicants")}
              />
            </Tooltip>
          )}
          {index > 0 && (
            <Tooltip title="Delete field">
              <Button
                className="company-name-button-delete button"
                icon={<MinusOutlined />}
                onClick={() => handleDeleteField("applicants", applicant.id)}
              />
            </Tooltip>
          )}
        </div>
      );
    });
  };

  const renderCountry = () => {
    return _.map(companyNameArray.countries, (country, index) => {
      const showLabel = index === 0;
      const lastItem =
        companyNameArray.countries[companyNameArray.countries.length - 1].id ===
        country.id;
      return (
        <div key={index} className="country-wrapper">
          {index > 0 && (
            <Tooltip title="Delete field">
              <Button
                className="country-buttom-delete button"
                icon={<MinusOutlined />}
                onClick={() => handleDeleteField("countries", country.id)}
              />
            </Tooltip>
          )}
          {lastItem && (
            <Tooltip title="Add new country field">
              <Button
                className="country-buttom button"
                icon={<PlusOutlined />}
                onClick={() => handleAddNewField("countries")}
              />
            </Tooltip>
          )}
          <Form.Item
            className="country"
            label={`${showLabel ? "country" : ""}`}
            name={`countries-${country.id}`}
          >
            <AutoComplete
              style={{
                width: 300,
              }}
              options={countryOptions}
              onSearch={onCountrySearch}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
        </div>
      );
    });
  };

  const renderCertifiedActivities = () => {
    return _.map(companyNameArray.certifiedActivities, (activities, index) => {
      const showLabel = index === 0;
      const lastItem =
        companyNameArray.certifiedActivities[
          companyNameArray.certifiedActivities.length - 1
        ].id === activities.id;
      return (
        <div key={index} className="registered-activity-wrapper">
          <Form.Item
            className="registered-activity"
            label={`${showLabel ? "registered activity: " : ""}`}
            name={`certifiedActivities-${activities.id}`}
          >
            <AutoComplete
              style={{
                width: 300,
              }}
              options={activitiesOptions}
              onSearch={onActivitiesSearch}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
          {lastItem && (
            <Tooltip title="Add new registered activity field">
              <Button
                className="registered-activity-button button"
                icon={<PlusOutlined />}
                onClick={() => handleAddNewField("certifiedActivities")}
              />
            </Tooltip>
          )}
          {index > 0 && (
            <Tooltip title="Delete field">
              <Button
                className="registered-activity-button-delete button"
                icon={<MinusOutlined />}
                onClick={() =>
                  handleDeleteField("certifiedActivities", activities.id)
                }
              />
            </Tooltip>
          )}
        </div>
      );
    });
  };

  const renderCertifiedProducts = () => {
    return _.map(
      companyNameArray.certifiedProducts,
      (certifiedProduct, index) => {
        const showLabel = index === 0;
        const lastItem =
          companyNameArray.certifiedProducts[
            companyNameArray.certifiedProducts.length - 1
          ].id === certifiedProduct.id;
        return (
          <div key={index} className="certified-product-wrapper">
            {index > 0 && (
              <Tooltip title="Delete field">
                <Button
                  className="certified-product-button button"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleDeleteField("certifiedProducts", certifiedProduct.id)
                  }
                />
              </Tooltip>
            )}
            {lastItem && (
              <Tooltip title="Add new certified product field">
                <Button
                  className="certified-product-button button"
                  icon={<PlusOutlined />}
                  onClick={() => handleAddNewField("certifiedProducts")}
                />
              </Tooltip>
            )}
            <Form.Item
              className="certified-product"
              label={`${showLabel ? "certified product: " : ""}`}
              name={`certifiedProducts-${certifiedProduct.id}`}
            >
              <AutoComplete
                style={{
                  width: 300,
                }}
                options={productsOptions}
                onSearch={onProductsSearch}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
          </div>
        );
      }
    );
  };

  const renderStandards = () => {
    return _.map(companyNameArray.standards, (standard, index) => {
      const showLabel = index === 0;
      const lastItem =
        companyNameArray.standards[companyNameArray.standards.length - 1].id ===
        standard.id;
      return (
        <div key={index} className="standard-wrapper">
          <Form.Item
            className="standard"
            label={`${showLabel ? "standard: " : ""}`}
            name={`standards-${standard.id}`}
          >
            <AutoComplete
              style={{
                width: 300,
              }}
              options={standardsOptions}
              onSearch={onStandardsSearch}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
          {lastItem && (
            <Tooltip title="Add new standard field">
              <Button
                className="standard-button button"
                icon={<PlusOutlined />}
                onClick={() => handleAddNewField("standards")}
              />
            </Tooltip>
          )}
          {index > 0 && (
            <Tooltip title="Delete field">
              <Button
                className="standard-button button"
                icon={<MinusOutlined />}
                onClick={() => handleDeleteField("standards", standard.id)}
              />
            </Tooltip>
          )}
        </div>
      );
    });
  };

  const handleAddNewField = (key) => {
    if (companyNameArray[key].length <= 14) {
      setCompanyNameArray((prev) => {
        return {
          ...prev,
          [key]: [...companyNameArray[key], { id: uuidv4() }],
        };
      });
    }
  };

  const handleDeleteField = (key, id) => {
    console.log(key, id, companyNameArray[key]);
    if (companyNameArray[key].length <= 15) {
      form.setFieldsValue({ [`${key}-${id}`]: undefined });
      setCompanyNameArray((prev) => {
        return {
          ...prev,
          [key]: _.filter(companyNameArray[key], (key) => key.id !== id),
        };
      });
    }
  };

  const resetSearchForm = () => {
    Promise.resolve(form.resetFields(), setCompanyNameArray(defaultFieldsArray))
      .then(() => {
        dispatch(clearAllSearchData());
      })
      .then(() => {
        dispatch(getCountriesSearchResults());
        dispatch(getApplicantsSearchResults());
        dispatch(getActivitiesSearchResults());
        dispatch(getProductsSearchResults());
        dispatch(getSourcesSearchResults());
        dispatch(getStandardSearchResults());
        dispatch(getSearchResults(25, 0, null));
      });
  };

  return (
    <>
      <Form
        className="search-page-form"
        name="search-page-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <div className="item-group">
          <div className="container left-side">
            <Tooltip
              title="Fill in the name of a certification body once at a time in a search field. If you want to search for multiple certification bodies press “+” to add one more search field. 
Use the officially used name of the certification body. Ex. “Ceres”, “Control Union”, “Acert”, “KIWA”, etc. 
"
            >
              <InfoCircleOutlined className="info-icon icon-for-certification" />
            </Tooltip>
            {renderSource()}
          </div>
          <div className="container right-side">
            <Tooltip
              title="Fill in the name of a company, once at a time in a search field. If you want to search for multiple companies press “+” to add one more search field. 
Use the officially used name of the company. Ex. “Organic Land Corporation”, “SELET HULLING PLC”, Sunvado ManufacturingPLC”, etc.
"
            >
              <InfoCircleOutlined className="info-icon icon-for-company" />
            </Tooltip>
            {renderCompanyName()}
          </div>
        </div>
        <div className="item-group">
          <div className="container left-side">
            <Tooltip
              title="Fill in the name of a country, once at a time in a search field. If you want to search for multiple countries press “+” to add one more search field. 
Use the officially used name of the country. Ex. “Ethiopia”, “Albania”, “Ukraine”, etc.
"
            >
              <InfoCircleOutlined className="info-icon icon-for-country" />
            </Tooltip>
            {renderCountry()}
          </div>
          <div className="container right-side">
            <Tooltip
              title="Fill in the activity type, once at a time in a search field. If you want to search for multiple activity types press “+” to add one more search field. 
Use the following activities: Production, Labeling, Processing, Wild collection, etc.
"
            >
              <InfoCircleOutlined className="info-icon icon-for-registered" />
            </Tooltip>
            {renderCertifiedActivities()}
          </div>
        </div>
        <div className="item-group">
          <div className="container left-side">
            <Tooltip
              title="Fill in the name of the product once at a time in a search field. If you want to search for multiple products press “+” to add one more search field. 
  In addition, some products can be identified using different names. Ex. IQF strawberry can be searched by using: “IQF strawberry” and “Frozen strawberry” or it can be combined in a broader category such as “Frozen berries”. In order to make your search as efficient as possible use multiple search names."
            >
              <InfoCircleOutlined className="info-icon icon-for-products" />
            </Tooltip>
            {renderCertifiedProducts()}
          </div>
          <div className="container right-side">
            <Tooltip
              title="Fill in the standard type, once at a time in a search field. If you want to search for multiple standards press ”+” to add one more search field. 
Use the following standards: EU, NOP, JAS, etc.
"
            >
              <InfoCircleOutlined className="info-icon icon-for-standard" />
            </Tooltip>
            {renderStandards()}
          </div>
        </div>
        <div className="buttons-group">
          <Form.Item className="submit-button">
            <Button htmlType="submit">Search</Button>
          </Form.Item>
          <Button className="reset-button" onClick={resetSearchForm}>
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default SearchForm;
