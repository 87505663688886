import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_ERROR,
  CREATE_NEW_USER_GET_USER_SUCCESS,
  DELETE_CURRENT_USER_REQUEST,
  DELETE_CURRENT_USER_ERROR,
  DELETE_CURRENT_USER_SUCCESS,
  ENABLE_DISABLE_USER_REQUEST,
  ENABLE_DISABLE_USER_ERROR,
  ENABLE_DISABLE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  CHANGE_USER_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_ERROR,
  CHANGE_USER_PASSWORD_SUCCESS,
} from "../constants/user";

const initialState = {
  isLoading: false,
  success: false,
  hasError: false,
  newUserSuccess: false,
  newUserHasError: false,
  deleteUserSuccess: false,
  deleteUserError: false,
  enableDisableSuccess: false,
  enableDisableError: false,
  updateUserSuccess: false,
  updateUserError: false,
  changeUserPasswordSuccess: false,
  changeUserPasswordError: false,
  user: null,
};

export const user = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case GET_USER_ERROR:
      return {
        ...state,
        success: false,
        hasError: true,
        isLoading: false,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
        user: payload,
      };

    case CREATE_NEW_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        newUserHasError: false,
      };

    case CREATE_NEW_USER_ERROR:
      return {
        ...state,
        newUserSuccess: false,
        newUserHasError: true,
        isLoading: false,
      };

    case CREATE_NEW_USER_GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_CURRENT_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        deleteUserError: false,
      };

    case DELETE_CURRENT_USER_ERROR:
      return {
        ...state,
        deleteUserSuccess: false,
        deleteUserError: true,
        isLoading: false,
      };

    case DELETE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ENABLE_DISABLE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        enableDisableError: false,
      };

    case ENABLE_DISABLE_USER_ERROR:
      return {
        ...state,
        enableDisableSuccess: false,
        enableDisableError: true,
        isLoading: false,
      };

    case ENABLE_DISABLE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        updateUserError: false,
      };

    case UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserError: true,
        updateUserSuccess: false,
        isLoading: false,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        changeUserPasswordError: false,
      };

    case CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        changeUserPasswordError: true,
        changeUserPasswordSuccess: false,
        isLoading: false,
      };

    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
