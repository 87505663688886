import { combineReducers } from "redux";
import { login } from "./login";
import { adminPage } from "./adminPage";
import { users } from "./users";
import { user } from "./user";
import { search } from "./search";
import { reset } from "./reset";

export default combineReducers({
  login,
  adminPage,
  users,
  user,
  search,
  reset,
});
