import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Button, List, Typography, Divider, Spin, Pagination } from "antd";
import { addNewUserModalOpen } from "../../redux/actions/adminPageActions";
import AddNewUserModal from "../../components/AddNewUserModal/AddNewUserModal";
import User from "../../components/User/User";
import { getAllUsers } from "../../redux/actions/usersActions";
import _ from "lodash";
import "./styles.scss";

const UserManagementPage = ({ allUsers }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const { isLoading } = users;
  const page = allUsers && allUsers.page;
  const pageSize = allUsers && allUsers.pageSize;
  const total = allUsers && allUsers.totalCount;

  const handleOpenCreateUserModal = () => {
    dispatch(addNewUserModalOpen());
  };

  const renderUsers = useCallback(() => {
    const usersList = allUsers && allUsers.items;

    if (_.isEmpty(allUsers && allUsers.items)) {
      return;
    }

    return (
      <List
        bordered
        dataSource={usersList}
        renderItem={(user) => (
          <List.Item>
            <Typography.Text>
              <User user={user} page={page} pageSize={pageSize}/>
            </Typography.Text>
          </List.Item>
        )}
      />
    );
  }, [allUsers]);

  const handlePageChange = (current, pageSize) => {
    dispatch(getAllUsers(current - 1, pageSize));
  }

  return (
    <Spin spinning={isLoading}>
      <Layout
        className="user-management-page"
        style={{ minHeight: "100vh", background: "#92C83E" }}
      >
        <AddNewUserModal
          page={page} pageSize={pageSize}
        />
        <div className="add-new-user">
          <Button className="create-user" onClick={handleOpenCreateUserModal}>
            Create new User
          </Button>
        </div>
        <Divider orientation="left" />
        {renderUsers()}
        <Divider orientation="left" />
        <Pagination current={page + 1} pageSize={pageSize} pageSizeOptions={[5, 10, 20, 50, 100]} total={total} onChange={(current, pageSize) => handlePageChange(current, pageSize)} />
      </Layout>
    </Spin>
  );
};

export default UserManagementPage;
