import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR
} from '../constants/users';

const initialState = {
  isLoading: false,
  success: false,
  hasError: false,
  users: null
}

export const users = (state = initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false
      }
    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        success: false,
        hasError: true,
        isLoading: false,
      }
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
        users: payload
      }
    default:
      return state;
  }
}