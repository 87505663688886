import {
  OPEN_ADD_USER_MODAL,
  CLOSE_ADD_USER_MODAL
} from "../constants/adminPage";

const initialState = {
  addUserModlaOpen: false
};

export const adminPage = (state = initialState, action) => {
  const { type } = action;

  switch(type) {
    case OPEN_ADD_USER_MODAL:
      return {
        ...state,
        addUserModlaOpen: true
      }
    case CLOSE_ADD_USER_MODAL:
      return {
        ...state,
        addUserModlaOpen: false
      }
    default:
      return state;
  }
};
