/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, BrowserRouter as Router } from "react-router-dom";

import UserManagementPage from "./pages/UserManagementPage/UserManagementPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import ResetPage from "./pages/ResetPage/ResetPage";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PublicRoute from "./components/PublicRoute/PublicRoute";
import { history } from "./helpers/history";

import { getCurrentUser } from "./redux/actions/userActions";
import { getAllUsers } from "./redux/actions/usersActions";
import NewPasswordPage from "./pages/ResetPage/NewPasswordPage";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const allDataUsers = users.users;
  const userDataInfo = user.user;
  const [allUsers, setAllUsers] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [roles] = userInfo ? userInfo.roles : [];
  const admin = roles && roles === "ROLE_ADMIN";

  useEffect(() => {
    if (!userDataInfo) {
      dispatch(getCurrentUser());
    }
  }, []);

  useEffect(() => {
    if (!allDataUsers) {
      dispatch(getAllUsers(0, 20));
    }
  }, []);

  useEffect(() => {
    setAllUsers(allDataUsers);
  }, [allDataUsers]);

  useEffect(() => {
    setUserInfo(userDataInfo);
  }, [userDataInfo]);

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path="/" component={LoginPage} exact />
        <PublicRoute path="/reset" component={ResetPage} exact />
        <PublicRoute path="/resetpassword" component={NewPasswordPage} exact />
        <PrivateRoute path="/searchpage" component={SearchPage} />
        {admin && (
          <PrivateRoute
            path="/usermanagementpage"
            component={() => <UserManagementPage allUsers={allUsers} />}
          />
        )}
      </Switch>
    </Router>
  );
};

export default App;
