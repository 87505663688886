import {
  USER_FORGOT_PASSWORD_ERROR,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
} from "../constants/reset";

const initialState = {
  success: false,
  isLoading: false,
  hasError: false,
  succesReset: false,
  errorReset: false,
  message: null,
};

export const reset = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        success: false,
        message: null,
        hasError: false,
        isLoading: true,
      };
    case USER_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        success: false,
        hasError: true,
        isLoading: false,
      };
    case USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        success: true,
        hasError: false,
        isLoading: false,
        message: payload,
      };
    case USER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case USER_RESET_PASSWORD_ERROR:
      return {
        ...state,
        succesReset: false,
        errorReset: true,
        isLoading: false,
        message: payload,
      };
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        succesReset: true,
        hasError: false,
        errorReset: false,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};
