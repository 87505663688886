import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { getAllUsers } from "../../redux/actions/usersActions";
import "./styles.scss";

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userDataInfo = user.user;
  const [userInfo, setUserInfo] = useState(null);
  const [roles] = userInfo ? userInfo.roles : [];
  const admin = roles === "ROLE_ADMIN";
  const token = localStorage.getItem('token');

  const getUsers = () => {
    dispatch(getAllUsers());
  };

  const logOutUser = () => {
    Promise.resolve(localStorage.removeItem("token")).then(() => {
      window.location.replace("/");
    });
  };

  useEffect(() => {
    setUserInfo(userDataInfo);
  }, [userDataInfo]);

  useEffect(() => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const { exp } = JSON.parse(jsonPayload);

    if (Date.now() >= exp * 1000) {
      logOutUser()
    }

  }, [token])

  return (
    <header className="header">
      <div className="header-nav">
        <NavLink to="/searchpage" activeClassName="is-active" exact>
          Search page
        </NavLink>
        {admin && (
          <NavLink
            to="/usermanagementpage"
            activeClassName="is-active"
            onClick={getUsers}
          >
            User management page
          </NavLink>
        )}
      </div>
      <Button
        className="log-out-button"
        icon={<LogoutOutlined />}
        onClick={logOutUser}
      >
        Logout
      </Button>
    </header>
  );
};

export default Header;
