import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({
  component: Component,
  token,
  ...rest }) => (
  <Route
    {...rest}
    component={props => (
      token ? (
        <Redirect to="/searchpage" />
      ) : (
        <Component {...props} />
      )
    )}
  />
)

const mapDispatchToProps = () => {
  return {
    token: localStorage.getItem('token')
  }
}

export default connect(mapDispatchToProps)(PublicRoute);
