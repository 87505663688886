export const GET_SEARCH_RESULTS_REQUEST = "GET_SEARCH_RESULTS_REQUEST";
export const GET_SEARCH_RESULTS_ERROR = "GET_SEARCH_RESULTS_ERROR";
export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";

export const EXPORT_TO_XLS_REQUEST = "EXPORT_TO_XLS_REQUEST";
export const EXPORT_TO_XLS_ERROR = "EXPORT_TO_XLS_ERROR";
export const EXPORT_TO_XLS_SUCCESS = "EXPORT_TO_XLS_SUCCESS";

export const EXPORT_TO_PDF_REQUEST = "EXPORT_TO_PDF_REQUEST";
export const EXPORT_TO_PDF_ERROR = "EXPORT_TO_PDF_ERROR";
export const EXPORT_TO_PDF_SUCCESS = "EXPORT_TO_PDF_SUCCESS";

export const GET_SEARCH_FIELDS = "GET_SEARCH_FIELDS";
export const GET_TABLE_PARAMS = "GET_TABLE_PARAMS";

export const CLEAR_ALL = "CLEAR_ALL";

export const SEARCH_BY_COUNTRIES_REQUEST = "SEARCH_BY_COUNTRIES_REQUEST";
export const SEARCH_BY_COUNTRIES_ERROR = "SEARCH_BY_COUNTRIES_ERROR";
export const SEARCH_BY_COUNTRIES_SUCCESS = "SEARCH_BY_COUNTRIES_SUCCESS";

export const SEARCH_BY_APPLICANTS_REQUEST = "SEARCH_BY_APPLICANTS_REQUEST";
export const SEARCH_BY_APPLICANTS_ERROR = "SEARCH_BY_APPLICANTS_ERROR";
export const SEARCH_BY_APPLICANTS_SUCCESS = "SEARCH_BY_APPLICANTS_SUCCESS";

export const SEARCH_BY_ACTIVITIES_REQUEST = "SEARCH_BY_ACTIVITIES_REQUEST";
export const SEARCH_BY_ACTIVITIES_ERROR = "SEARCH_BY_ACTIVITIES_ERROR";
export const SEARCH_BY_ACTIVITIES_SUCCESS = "SEARCH_BY_ACTIVITIES_SUCCESS";

export const SEARCH_BY_PRODUCTS_REQUEST = "SEARCH_BY_PRODUCTS_REQUEST";
export const SEARCH_BY_PRODUCTS_ERROR = "SEARCH_BY_PRODUCTS_ERROR";
export const SEARCH_BY_PRODUCTS_SUCCESS = "SEARCH_BY_PRODUCTS_SUCCESS";

export const SEARCH_BY_SOURCES_REQUEST = "SEARCH_BY_SOURCES_REQUEST";
export const SEARCH_BY_SOURCES_ERROR = "SEARCH_BY_SOURCES_ERROR";
export const SEARCH_BY_SOURCES_SUCCESS = "SEARCH_BY_SOURCES_SUCCESS";

export const SEARCH_BY_STANDARTS_REQUEST = "SEARCH_BY_STANDARTS_REQUEST";
export const SEARCH_BY_STANDARTS_ERROR = "SEARCH_BY_STANDARTS_ERROR";
export const SEARCH_BY_STANDARTS_SUCCESS = "SEARCH_BY_STANDARTS_SUCCESS";