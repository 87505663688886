import {
  USER_FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
} from "../../helpers/apiConstants";
import {
  USER_FORGOT_PASSWORD_ERROR,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
} from "../constants/reset";

const URL = "https://tradin-dsa.com/";
// const URL = "https://tradin-dsa.com/";

export const forgotPassword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_FORGOT_PASSWORD_REQUEST });

    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
      },
      redirect: "follow",
    };

    let finishRequestPayload = null;
    const endpoint = `${URL}${USER_FORGOT_PASSWORD}?email=${payload.email}`;

    try {
      const response = await fetch(endpoint, requestOptions);
      const result = await response.text();
      finishRequestPayload = result;
    } catch (error) {
      console.log("Error forgot password request from the server", error);
    }

    if (!finishRequestPayload) dispatch({ type: USER_FORGOT_PASSWORD_ERROR });

    return dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: finishRequestPayload,
    });
  };
};

export const resetPassword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_RESET_PASSWORD_REQUEST });

    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
      },
      redirect: "follow",
    };

    let finishRequestPayload = null;
    const endpoint = `${URL}${USER_RESET_PASSWORD}?password=${payload.password}&token=${payload.token}`;

    try {
      const response = await fetch(endpoint, requestOptions);
      const result = await response.text();
      finishRequestPayload = result;
    } catch (error) {
      console.log("Error reset password request from the server", error);
    }

    if (!finishRequestPayload)
      dispatch({
        type: USER_RESET_PASSWORD_ERROR,
        payload: finishRequestPayload,
      });

    return dispatch({
      type: USER_RESET_PASSWORD_SUCCESS,
      payload: finishRequestPayload,
    });
  };
};
