/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Button, Layout, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { UserOutlined } from "@ant-design/icons";

import { forgotPassword } from "../../redux/actions/resetActions";

import { dataBaseLogo } from "../../assets/images";

import "./styles.scss";

const validation_schema = {
  email: [
    {
      type: "email",
      message: "The input is not valid email!",
    },
    {
      required: true,
      message: "Please input your email!",
    },
  ],
};

const ResetPage = () => {
  const dispatch = useDispatch();
  const reset = useSelector((state) => state.reset);
  const messageSuccess =
    "We have sent a reset password link to your email. Please check.";
  const messageState = reset.message;

  const [emailForm] = Form.useForm();
  const [sendEmail, setSendEmail] = useState(false);

  const onFinishEmail = (values) => {
    Promise.resolve(dispatch(forgotPassword(values))).then(() => {
      setSendEmail(true);
    });
  };

  useEffect(() => {
    if (messageState === messageSuccess) {
      message.success(messageState);
    }
    if (messageState && messageState !== messageSuccess) {
      message.error("Failed");
    }
  }, [messageState]);

  return (
    <Layout
      className="reset-page"
      style={{ minHeight: "100vh", background: "#92C83E" }}
    >
      <div className="logo-group">
        <div className="data-base-logo">{dataBaseLogo()}</div>
      </div>

      <Form
        className="reset-form"
        name="reset-email-form"
        form={emailForm}
        onFinish={onFinishEmail}
      >
        <Form.Item
          className="user-name"
          label="Enter your email address"
          name="email"
          rules={validation_schema.email}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            style={{
              borderRadius: "8px",
            }}
          />
        </Form.Item>

        <div className="flex-buttons">
          {!sendEmail ? (
            <Form.Item className="submit-button">
              <Button htmlType="submit">Receive code</Button>
            </Form.Item>
          ) : null}

          {sendEmail ? (
            <Form.Item className="submit-button">
              <Button htmlType="submit">Re-send code</Button>
            </Form.Item>
          ) : null}
        </div>
      </Form>
    </Layout>
  );
};

export default ResetPage;
