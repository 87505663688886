import React from 'react';
import './styles.scss';

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <span className="footer-title">
        Dear Colleagues,
      </span>
      <div className="footer-sections-wrapper">
        <div className="left-side">
          <span>
            I have the pleasure to present you our DSA search engine. Now you can make use of our internal tool to search organic certified entities from all around the world.
          </span>
          <span>
            In the nutshell, this engine combines information from the clients lists of most of the certification bodies which offer organic inspection services in line with international organic standards.
          </span>
          <span>
            You can use this tool for the following purposes:
          <ul>
              <li>
                - To find new suppliers of your group of products.
            </li>
              <li>
                - To find new customers.
            </li>
            </ul>
          </span>
        </div>
        <div className="right-side">
          <span className="right-side-title">
            Please note, the search engine as well as the database are still in construction, thus you may encounter the following constraints:
        </span>
          <ul>
            <li>
              - Missing info such as certified products. The constraint is due to the fact that many certification bodies do not publish the certified products of their clients. Certification bodies that do not publish such info are: Ceres, Ecocert, Albinspekt, etc.
          </li>
            <li>
              - Missing information such as address, contact details.
          </li>
            <li>
              - Recently decertified companies can still be shown as an organic entity.
          </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom-section">
        <span>
          If you have any additional questions, do not hesitate to inform us.
        </span>
        <span>
          For any questions concerning the DSA, please address your question to:
          <a href="mailto:igor.gaina@tradinorganic.com">
            igor.gaina@tradinorganic.com
          </a>
        </span>
      </div>
    </div>
  )
}

export default Footer;
