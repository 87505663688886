import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "antd";
import _ from "lodash";
import {
  getSearchResults,
  exportToXls,
  exportToPdf,
  getTableParams,
} from "../../redux/actions/searchActions";

const defaultPagination = {
  current: 0,
  pageSize: 25,
  pageSizeOptions: ["10", "20", "25", "50", "100"],
};

const SearchResultsTable = ({ searchResults }) => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(defaultPagination);
  const [dataFromFields, setDataFromFields] = useState(null);
  const [currentInfoFromTable, setCurrentInfoFromTable] = useState(null);
  const { items, page, pageSize, totalCount } = searchResults
    ? searchResults
    : [];

  const dataFromSearchFields = useSelector(
    (state) => state.search.searchFieldsData
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: totalCount,
      current: page + 1,
      pageSize: pageSize,
    }));
  }, [page, pageSize, totalCount]);

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { order, field } = sorter;
    let orderType;

    if (order === "ascend") {
      orderType = "asc";
    } else if (order === "descend") {
      orderType = "desc";
    } else {
      orderType = undefined;
    }

    const sortType = orderType ? `sort=${field},${orderType}` : null;

    setCurrentInfoFromTable({
      pageSize: pageSize,
      current: current,
      sortType: sortType,
    });

    dispatch(getSearchResults(pageSize, current, sortType, dataFromFields));
  };

  useEffect(() => {
    setDataFromFields(dataFromSearchFields);
  }, [dataFromSearchFields]);

  useEffect(() => {
    dispatch(getTableParams(currentInfoFromTable));
  }, [currentInfoFromTable, dispatch]);

  const columns = [
    {
      title: "Certification body",
      dataIndex: "source",
      width: 80,
      align: "center",
      sorter: true,
    },
    {
      title: "Company name",
      dataIndex: "applicant",
      width: 150,
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      width: 100,
      sorter: true,
    },
    {
      title: "Address",
      dataIndex: "address",
      width: 150,
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "telephones",
      render: (telephones) => {
        return (
          <div className="phones-wrapper">
            {_.map(telephones, (phone, index) => (
              <span key={index} className="email">
                {phone}
              </span>
            ))}
          </div>
        );
      },
      width: 90,
    },
    {
      title: "Email",
      dataIndex: "emails",
      render: (emails) => {
        return (
          <div className="emails-wrapper">
            {_.map(emails, (email, index) => (
              <span key={index} className="email">
                {email}
              </span>
            ))}
          </div>
        );
      },
      width: 140,
    },
    {
      title: "Certified activities",
      dataIndex: "certifiedActivities",
      width: 120,
      sorter: true,
    },
    {
      title: "Certified products",
      dataIndex: "certifiedProducts",
      width: 180,
      sorter: true,
    },
    {
      title: "Standard",
      dataIndex: "standard",
      width: 180,
      sorter: true,
    },
  ];

  const data =
    items &&
    _.map(items, (item, index) => {
      const {
        source,
        applicant,
        country,
        address,
        telephones,
        emails,
        certifiedActivities,
        certifiedProducts,
        standard,
      } = item;

      return {
        key: index,
        source,
        applicant,
        country,
        address,
        telephones,
        emails,
        certifiedActivities,
        certifiedProducts,
        standard,
      };
    });

  const getXlsFile = () => {
    dispatch(exportToXls(dataFromSearchFields));
  };

  const getPdfFile = () => {
    dispatch(exportToPdf(dataFromSearchFields));
  };

  return (
    <>
      <Table
        className="search-results-table"
        bordered={true}
        columns={columns}
        dataSource={data}
        style={{
          background: "red",
        }}
        pagination={pagination}
        scroll={{ y: "100%", x: 1700 }}
        onChange={handleTableChange}
      />
      <div className="export-buttons-group">
        <Button className="export-to-xls" onClick={getXlsFile}>
          Export to XLS
        </Button>
        <Button className="export-to-pdf" onClick={getPdfFile}>
          Export to PDF
        </Button>
      </div>
    </>
  );
};

export default SearchResultsTable;
